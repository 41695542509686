
import SupplierModal from './SupplierModal'
import ResponseModal from './ResponseModal'
import { RECALL_STATUS, RECALL_CLASSIFICATIONS } from '../../../misc/commons'
import userServices from '../../../services/user'
import fileDownload from 'js-file-download'
import conf from '../../../config'
export const API_ROOT = `${conf.API_URL}`
export default {
    components: {
        SupplierModal,
        ResponseModal
    },
    data() {
        return {
            total: 0,
            items: [],
            listSupplier: [],
            searchQuery: {},
            header: [
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Supplier Name',
                    name: 'suppliername',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Recall ID',
                    name: 'userRecallId',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Recall Name',
                    name: 'name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Documents',
                    name: 'documents',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes:'wordbreakall vertical-align-top'
                },
                {
                    label: 'Notification Date',
                    name: 'notificationDate',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Response Date',
                    name: 'date',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Affected Product',
                    name: 'disposalMethod',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: '',
                    name: 'action',
                    slot_name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right',
                }
            ],

            // listCategories: [],
            recall_status: RECALL_STATUS,
            recall_classification: RECALL_CLASSIFICATIONS,
            supplierModalKey: 0,
            respModalKey: 0,
            customPagination: false
        }
    },
    created() {
        //alert(API_ROOT)
        this.fetchData()
       console.clear();
    },
    mounted() {

        this.findSupplier()
    },
    
    methods: {
        findSupplier() {
            this.listSupplier = [
                { name: 'All', id: null }
            ]
        },
        onCompleted() {
            this.fetchData()
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        deleteByGUID(val) {
            this._showConfirm('Are you sure you want to delete the recall, this cannot be undone?', { title: 'Delete Recall', variant: 'danger' }).then(confirm => {
                if (confirm) {
                    userServices.deleteByGUID(val).then(resp => {
                        this.fetchDelay("Recall deleted successfully")
                    })
                }
            })
           console.clear();
        },
        fetchData(val,mode,recallid) {
            //debugger
            var re = recallid
            var uId = localStorage.getItem('userId');
            var result = this.searchQuery;
            var name = null;
            var order = null;
            if (this.searchQuery.sort != undefined) {
                name = this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null;
                order = this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null;
            }
            userServices.getRecallList({
                ...this.searchQuery,
                uId,
                sortField: name,
                sortDirection: order,
                pageSize: this.searchQuery.pageSize ? this.searchQuery.pageSize : 10,
                pageNumber: this.searchQuery.pageNumber ? this.searchQuery.pageNumber : 10
            }).then(resp => {
                //debugger
                this.items = [];
                Date.prototype.yyyymmdd = function () {
                    var mm = this.getMonth() + 1; // getMonth() is zero-based
                    var dd = this.getDate();
                    //console.log(this)
                    return ((dd > 9 ? '' : '0') + dd) + "/" + ((mm > 9 ? '' : '0') + mm) + "/" + this.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                };
                if (resp.data.RecallResponse) {
                    //debugger
                    resp.data.RecallResponse.forEach((x, i) => {
                        ////debugger
                        if (x.IsDeleted != "1") {
                            var date = "";
                            var Ndate = "";
                            if (x.ResponseDate != '01/01/1900' && x.ResponseDate != null && x.ResponseDate != undefined && x.ResponseDate != "") {
                                date = x.ResponseDate
                            }
                            else {
                                date = "";
                            }
                            if (date != "") {
                                var Tdate = new Date(x.ResponseDate);
                                var mm = Tdate.getMonth() + 1;
                                var dd = Tdate.getDate();
                                Ndate = ((dd > 9 ? '' : '0') + dd) + "/" + ((mm > 9 ? '' : '0') + mm) + "/" + Tdate.getFullYear() + " " + Tdate.getHours() + ":" + Tdate.getMinutes() + ":" + Tdate.getSeconds();
                            }
                            //debugger
                            this.items.push({
                                recallid: x.RecallId,
                                userRecallId: x.UserRecallId,
                                name: x.supplierRecallName,
                                suppliername: x.supplierName,
                                supplierEmail: x.supplierEmail,
                                supplierRecallId: x.supplierRecallId,
                                notificationDate: x.notificationDate != null && x.notificationDate.includes('/') ? x.notificationDate.split(' ')[0] + " 01:01:01 PM" : x.notificationDate,
                                disposalMethod: x.disposalMethod,
                                affectedProduct: x.affectedProduct,
                                FileLen:0,
                                status: x.ResponseStatus,
                                date: Ndate,
                                documents: "",
                                Files: x.Files,
                                userId: x.userId,
                                IsFirstNotice: x.IsFirstNotice,
                                notificationValue: x.NotificationValue
                            });
                        }
                    })
                    ////debugger
                    this.items.forEach((x, i) => {
                        
                        x.Files.forEach((y, i) => {
                            if (y.FileName != "" && y.FileName != null && y.FileName != undefined) {
                                y.Ext = y.FileName.split('.')[1]
                                x.FileLen = x.FileLen + 1;
                            }
                        })
                        //
                    })
                    //debugger
                    this.total = resp.data.TotalRecordCount;
                    if (val != null && val != undefined && val != "") {
                        this._showToast(val, { variant: 'success' })
                    }
                    if (mode != null && mode != undefined && mode != "") {
                        this.items.forEach((x, i) => {
                            if (x.supplierRecallId == mode ) {
                            //console.log(x)
                            this.RespClick(x)
                            }
                        })
                    }
                }
                else {
                   console.clear();
                    this._showToast('Something went wrong', { variant: 'danger' })
                }
            })
        },
        addMoreClick() {
           console.clear();
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderInsert(this.items)
            })
        },
        EditClick(val) {
           console.clear();
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'UPDATE')
            })
        },
        addMoreRespClick(val,mode) {
           console.clear();
            //debugger
            if(val.FileLen > 0 && val.supplierEmail != "" && val.supplierEmail != null ){
                if (mode == 'btn'){
                // if(val.status == 'SENT')   
                // {
                    this.respModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.respModal.forceRerenderInsert(val)
                    })
                //}
                }
                else if(mode == 'opt')
                {
                    if(val.status == 'ERROR')   
                    {
                        this.supplierModalKey += 1
                        this.$nextTick(() => {
                            this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                        })
                    }
                    else
                    {
                        this.respModalKey += 1
                        this.$nextTick(() => {
                        this.$refs.respModal.forceRerenderInsert(val)
                    })   
                    }
                }
            }
            else 
            {
                if(val.status == 'SENT')   
                {
                    this.respModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.respModal.forceRerenderInsert(val)
                    })
                }
                else if (mode == 'btn'){
                    if(val.status == 'ERROR')   
                    {
                        this.respModalKey += 1
                        this.$nextTick(() => {
                        this.$refs.respModal.forceRerenderInsert(val)
                    })   
                    }
                    else if(val.status == 'DRAFT')   
                    {
                        this.supplierModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                    }) 
                    }
                    else
                    {
                        this.respModalKey += 1
                        this.$nextTick(() => {
                        this.$refs.respModal.forceRerenderInsert(val)
                    })   
                    }
                }
                else    
                {
                    this.supplierModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.supplierModal.forceRerenderUpdate(val, this.items,'RES_UPDATE')
                    })
                }
            }
        },
        RespClick(val) {
           console.clear();
            //debugger
            if(val.FileLen > 0 && val.supplierEmail != "" && val.supplierEmail != null){
                this.respModalKey += 1
                this.$nextTick(() => {
                    this.$refs.respModal.forceRerenderInsert(val)
                })
            }
        },
        downloadFile(url) {
           console.clear();
            debugger
            this.openExternalLink(url)
        },
        viewRecall() {
            //this.$refs.modal.hide()
        },
        getRecallByUId(recallId) {
           console.clear();
            this.EditClick(recallId)
        },
        downloadURI(uri, name) {
            debugger
            var link = document.createElement("a");
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            //delete link;
          },
          async createFile(dataurl, filename,contentType){
            ////debugger
            let headers = new Headers();
            headers.append('Content-Type', contentType);
            headers.append('Access-Control-Allow-Origin', '*');
            headers.append('GET','POST','PUT', 'DELETE');
            let response = await fetch(dataurl,{
                mode: 'cors',
                //method: 'POST',
                //credentials: 'include',
                headers: headers
            });
            let data = await response.blob();
            let metadata = {
              type: contentType
            };
            //debugger
            let file = new File([data], filename, metadata);
            // ... do something with the file or return it
          },
          async download(dataurl, filename,contentType) {
              debugger
              if(contentType == 'application/pdf')
              {
               console.clear();
                this.openExternalLink(dataurl)   
              }
              else 
              {
                let headers = new Headers();
                headers.append('Content-Type', contentType);
                headers.append('Access-Control-Allow-Origin', '*');
                headers.append('GET','POST','PUT', 'DELETE');
                await fetch(dataurl ,{
                    mode: 'cors',
                    headers: headers
                })
                .then(res => res.blob()) 
                .then(blob => {
                    //debugger
                    if(contentType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                    {
                        filename = filename.split('.')[0] + ".docx"
                    }
                    else if(contentType == "text/plain")
                    {
                        filename = filename.split('.')[0] + ".txt"
                    }
                    else if(contentType == "application/vnd.ms-excel")
                    {
                        filename = filename.split('.')[0] + ".csv"
                    }
                    else if(contentType == "application/vnd.openxmlformats-officedocument.presentationml.presentation")
                    {
                        filename = filename.split('.')[0] + ".ppt"
                    }
                    else if(contentType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                    {
                        filename = filename.split('.')[0] + ".xlsx"
                    }
                    //
                  fileDownload(blob, filename,contentType)
              }).catch(function(err) {
                //debugger
            });
              }
          }
    }
}