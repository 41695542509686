import userServices from '../../../../services/user'
//import emailjs from 'emailjs-com';
export default {
    components: {
    },
    data() {
        return {
            item: [],
            admin: [],
            formType: 'INSERT',
            status: '',
        }
    },
    created() {
       console.clear();
      },
    mounted() {
    },
    computed: {
        form() {
            if (this.formType == 'INSERT') {
                return {
                    title: 'Would you like to send recall response to supplier?',
                    showAdminCreation: true,
                    showStatusSwitcher: false
                }
            }
            if (this.formType == 'UPDATE') {
                return {
                    title: 'Update Supplier',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
        },
    },
    methods: {
        sendEmail(e) {
           console.clear();
            this.beforeupdateRecall()
           console.clear();
            var toEmail = this.item.supplierEmail
            var fileP = [];
            this.item.Files.forEach((x, i) => {
                if (x.FilePath != "Empty") {
                    fileP.push({
                        "FileName": x.FileName != undefined ? x.FileName : x.fileName,
                        "FilePath": x.FilePath!= undefined ? x.FilePath : x.filePath
                    })
                }
            });
            //debugger            
var myvar = '<div class="x_1362993703col-md-12" style="/* margin-bottom :  30px; */"><br><span class="x_1362993703mb-0" style="color :  black; "><span>Dear </span> <span style="font-weight: 700;">' + ( this.item.suppliername == undefined ? this.item.supplierName: this.item.suppliername ) + ',</span></span><br>'+
'<br><span class="x_1362993703mb-0" style="color :  black; "><span>Please see attached the response you requested regarding your recall <span style="font-weight :  600; ">'+ ( this.item.name == undefined ? this.item.supplierRecallName : this.item.name) +'</span>. This response has been returned to you via NotiSphere.</span></span><span class="x_1362993703mb-0" style="color :  black; "><span> NotiSphere is changing the way recall alerts are handled in the industry, by electronically connecting suppliers and providers to communicate recall and other product related information.</span></span><br><span class="x_1362993703mb-0" style="color :  black; "><br><span>My organization is embracing this technology to solve the many challenges that recall alerts present to hospitals today.</span></span>'+
'<span class="x_1362993703mb-0" style="color :  black; "><span> We urge you to begin submittiing recall alerts electronically through NotiSphere to significantly improve our ability to respond to recalls quickly and effectively.</span></span><br><br><span class="x_1362993703mb-0" style="color :  black; "><span>To learn more about NotiSphere go to </span><span style="text-decoration :  underline; color :  rgb(0, 158, 251); "><a href="https://www.notisphere.com/notisphere-info" target="_blank">https://www.notisphere.com/notisphere-info</a></span></span><br><br><span class="x_1362993703mb-0" style="color :  black;padding-bottom: 5px !important;"><span></span><span style="font-weight: 700;/* padding-bottom: 10em; */">Sincerely,</span></span><br>'+
'<span class="x_1362993703mb-0" style="color :  black; padding-bottom :  10px; "><span style="font-weight: 700;">' + this.admin.name + '</span></span><br>'+
'<span class="x_1362993703mb-0" style="color :  black; "><span style="font-weight: 700;">' + this.admin.title + '</span></span><br><span class="x_1362993703mb-0" style="color :  black; "><span style="font-weight: 700;">' + this.admin.companyname + '</span></span></div>';
            var itemData =
            {
                "Subject": "NotiSphere ER Response",
                "FromEmail": "support@notisphererecalls.com",
                "ToEmail": toEmail,
                //"Body": this.$refs.modal.$refs.body.children[3].innerHTML,
                "Body": myvar,
                "FileAttachment": fileP,
                "RecallId": this.item.recallid != undefined ? this.item.recallid : this.item.RecallId,
            }
            //debugger
            userServices.sendResponse(itemData).then(resp => {
                //debugger
               console.clear();
                this.updateRecall(resp.data.data)
               console.clear();
            })
        },
        beforeupdateRecall() {
            var itemData = {
                "Files": this.item.Files,
                "RecallId": this.item.recallid != undefined ? this.item.recallid : this.item.RecallId ,
                "supplierEmail": this.item.supplierEmail,
                "supplierName": this.item.suppliername!= undefined ? this.item.suppliername : this.item.supplierName,
                "supplierRecallId": this.item.userRecallId,
                "supplierRecallName": this.item.name != undefined ? this.item.name : this.item.supplierRecallName,
                "userId": this.item.userId,
                'userRecallId': this.item.supplierRecallId,
                "responceStatus": "SENT",
                "createdAt": "10/10/2020",
                "disposalMethod": this.item.disposalMethodValue == undefined ? this.item.disposalMethod: this.item.disposalMethodValue, 
                "affectedProduct": this.item.affectedProduct, 
                "notificationDate": this.item.notificationDate,
                "messageeId": ''
            }
            //debugger
            userServices.updateRecall(itemData).then(resp => {
               console.clear();
                //console.log(resp)   supplierRecallName
            })
        },
        updateRecall(msgID) {

            var itemData = {
                "Files": this.item.Files,
                "RecallId": this.item.recallid != undefined ? this.item.recallid : this.item.RecallId ,
                "supplierEmail": this.item.supplierEmail,
                "supplierName": this.item.suppliername!= undefined ? this.item.suppliername : this.item.supplierName,
                "supplierRecallId": this.item.userRecallId,
                "supplierRecallName": this.item.name != undefined ? this.item.name : this.item.supplierRecallName,
                "userId": this.item.userId,
                'userRecallId': this.item.supplierRecallId,
                "responceStatus": "Update",
                "createdAt": "10/10/2020",
                "disposalMethod": this.item.disposalMethodValue == undefined ? this.item.disposalMethod: this.item.disposalMethodValue, 
                "affectedProduct": this.item.affectedProduct, 
                "notificationDate": this.item.notificationDate,
                "messageeId": msgID
            }
            //debugger
            userServices.updateRecall(itemData).then(resp => {
                //debugger
                this.$refs.modal.hide()
                this.$parent.$options.parent.fetchData("Response sent successfully")
                if (resp.data.data) {
                }
            })
        },
        forceRerenderInsert(val) {
            //debugger
            this.status = val.status == undefined ? val.responceStatus : val.status
            this.item = val
            var storedNames = JSON.parse(localStorage.getItem("userD"));
            this.admin.name = storedNames[1] + " " + storedNames[2];
            this.admin.email = storedNames[3];
            this.admin.title = storedNames[4];
            this.admin.companyname = storedNames[5];
            this.admin.phoneno = storedNames[6];
            this.formType = 'INSERT'
            this.$refs.modal.show()
           console.clear();
        },
        cancel() {
            this.$refs.modal.hide()
           console.clear();
        }
    }
}