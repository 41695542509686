import { RECALL_CLASSIFICATIONS } from '../../../../misc/commons'
import VueDropzone from 'vue2-dropzone'
import userServices from '../../../../services/user'
import ResponseModal from '../ResponseModal'
import S3 from 'aws-s3'
import { uuid } from 'vue-uuid';
import conf from '../../../../config'
export const S3Url = `${conf.S3URL}`
export const S3BUCKET = `${conf.S3BUCKET}`
export default {
    components: {
        VueDropzone,
        ResponseModal
    },
    sprops: ['infoData', 'recallMetaData', 'recallDisabled'],
    data() {
        return {
            uuid: uuid.v1(),
            item: {
                suppliername: null,
                name: null,
                recallid: null,
                id: null,
                response_email: null,
                documents: null,
                createdBy: null,
                notificationDate: null,
                supplierEmail:null,
                notificationDate: '',
                affectedProduct: false,
                IsFirstNotice: false,
                disposalMethod: '',
                disposalMethodValue: '',
            },
            IsVal: false,
            notificationValue: 0,
            notone: 1,
            nottwo: 2,
            notthree: 3,
            notfour: 4,
            class1: '',
            class2: '',
            class3: '',
            class4: '',
            css1: 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;',
            css2: 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;',
            css3: 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;',
            css4: 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;',
            formType: 'INSERT',
            showNote: false,
            recallId: null,
            errorList: [],
            RecallsList: [],
            sup:'sup',
            sup1:'sup1',
            filesUpload: [],
            filespaths: [],
            s3upload: [],
            IsUpdated:false,
            filesUploadUpdate: [],
            filesUploadOriginal: [],
            respModalKey: 0,
            cnt: 0,
            uploadcnt: 0,
            thumbnailIndex: -1,
            updateRecallId: '',
            
        }
    },
    mounted() {
        this.info = _.cloneDeep(this.infoData)
        localStorage.removeItem('FileContent')
    },
    created() {
        ////debugger
        console.clear();
      },
      watch: {
        //'item.response_email'(val){
            //await this.$refs['observer'].validate()
            //debugger
            //this.$refs.modal.$refs.body.children[2].children[0].children[1].textContent
            // if(this.$refs.modal.$refs.body.children[1] == undefined)
            // {
            //     if(this.$refs.modal.$refs.body.children[2].children[0].children[1].children[0].children[0].children[1].textContent != '')
            //     {
            //         //debugger
            //     }
            // }
            // else    
            // {
            //     if(this.$refs.modal.$refs.body.children[1].children[0].children[1].children[0].children[0].children[1].textContent != '')
            //     {
            //         //debugger
            //     }
            // }
            //debugger
    //       if(this.filesUpload.length > 0 && this.item.response_email != ""){

    //       }
    //   },
      'filesUpload'(val){
        if(this.filesUpload.length > 0 && this.item.response_email != ""){

        }
    },
    'item.notificationDate'(val){
       console.clear();
    }
        },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'Add Recall Response',
                    showAdminCreation: true,
                    showStatusSwitcher: false,
                    class:"card-title"
                }
            } 
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Recall Response',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
            if(this.formType == 'RES_UPDATE'){
                return {
                    title: 'RECALL RESPONSE',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
        },
        S3Url()        
        {
            return S3Url
            //return 'https://prod-er-data.s3.us-east-2.amazonaws.com'
            //return 'https://qa-er-data.s3.us-east-2.amazonaws.com'
        },
        FileNameByGUID()
         {  
            var Tdate = new Date();
            var txt = Tdate.valueOf()
             
            return  Math.random().toString().replace('.','').substr(10)//txt.toString().substr(7)//this.$uuid.v4().toString().substr(29)
          }  ,
          GetUserId(){
              return localStorage.getItem('userId');
          }
    },
    methods:{
        // //async onChangeEvent(val){
        //     onChangeEvent(val){
        //     debugger
        //     //const isValid = await this.$refs['observer'].validate()
        //     debugger
        // },
        
        async removeDocument(index) {
            if (this.formType == "INSERT") {
                this.filesUpload.splice(index, 1)
                this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
                var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                storedNames.splice(index, 1)
                localStorage.removeItem('FileContent')
                localStorage.setItem("FileContent", JSON.stringify(storedNames));
            }
            else {
                //
                let Olen = this.filesUpload.length
                let Nlen = this.filesUploadUpdate.length
                let NIndex = Olen - Nlen
                let NIndexA = Olen - NIndex
                if ((index + 1) > NIndex) {
                    let A = index - NIndex
                    this.filesUploadUpdate.splice(A, 1)
                    var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    storedNames.splice(A, 1)
                    localStorage.removeItem('FileContent')
                    localStorage.setItem("FileContent", JSON.stringify(storedNames));
                }
                else{
                    this.filesUploadOriginal.splice(index, 1)
                }
                this.filesUpload.splice(index, 1)
                
                this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
                if(this.formType == 'RES_UPDATE'){
                    //debugger
                    if(this.item.response_email != "" && this.filesUpload.length > 0)
                    {
                        // if(this.item.status != 'Bounce')
                        // {
                        //     this.$refs.modal.$refs.body.children[0].hidden = true
                        // }
                        this.$refs.modal.$refs.footer.children[1].hidden = true
                        this.$refs.modal.$refs.footer.children[2].hidden = false
                    }
                    else
                    {
                            //this.$refs.modal.$refs.body.children[0].hidden = true
                            this.$refs.modal.$refs.footer.children[1].hidden = false
                            this.$refs.modal.$refs.footer.children[2].hidden = true
                    }
                }
                debugger
                if (this.formType == 'RES_UPDATE') {
                    const isValid = await this.$refs['observer'].validate()
                    if(this.item.Files.length > 0 || this.filesUpload.length > 0 && isValid )
                        this.IsVal = true
                    else 
                        this.IsVal = false
                }
            }
           console.clear();
        },
        GetContentType(type,ext){
            if(type == 'application/vnd.ms-excel')
            {
                return 'application/'  + ext;
            }
            else if(type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            {
                return 'application/' + ext;
            }
            else if(type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
            {
                return 'application/'  + ext;
            }
            else if(type == 'image/svg+xml')
            {
                return 'image/'  + ext;
            }
            else if(type == 'text/plain')
            {
                return 'application/'  + ext;
            }
            else 
            {
                return type;
            }
        },
        isChange(eve){
            this.item.disposalMethod = ''
            this.item.disposalMethodValue = ''
            if (eve.target.checked) {
                this.item.affectedProduct = true;
            }
            else {
                this.item.affectedProduct = false;
            }
            ////debugger
        },
        isDisposal(eve){
            if (eve) {
                this.item.disposalMethodValue = 'Destroyed'
            }
            else {
                this.item.disposalMethodValue = 'Returned'
            }
            ////debugger
        },
        async handleFilesUpload() {
           console.clear();
            const config = {
                //bucketName: 'prod-er-data',
                bucketName: S3BUCKET,
                //bucketName: 'qa-er-data',
                //dirName: this.GetUserId,
                dirName: this.$uuid.v4(),
                region:'us-east-2',
                accessKeyId:'AKIA3PVXGLRQ6AHCKHWW',
                secretAccessKey:'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',
                s3Url: this.S3Url,
            }
            const S3Client = new S3(config);
            let uploadedFiles = this.$refs.files.files
            this.uploadcnt = 0;
            this.cnt = 0;
            this.uploadcnt = uploadedFiles.length
            this._loadingStart()
            for ( let i = 0; i < uploadedFiles.length; i++ ) {
                let fileNameUpload = uploadedFiles[i].name.split(".")[0]   +'_'+ Math.random().toString().replace('.','').substr(11)//this.FileNameByGUID;// + "_" + this.$uuid.v4().toString().substr(29);
                this.s3upload.push({
                    name: fileNameUpload,
                })
                if (this.formType == "INSERT") {
                    this.filesUpload.push({
                        name: fileNameUpload +'.' + uploadedFiles[i].name.split('.')[1],//uploadedFiles[i].name,
                        size: uploadedFiles[i].size,
                        type: uploadedFiles[i].type,
                        file: uploadedFiles[i],
                    })
                  }
                  else  {
                      
                    this.IsUpdated = true;
                    this.filesUpload.push({
                        //name: uploadedFiles[i].name,
                        name: fileNameUpload +'.' + uploadedFiles[i].name.split('.')[1],
                        type: uploadedFiles[i].type,
                        size: uploadedFiles[i].size,
                        path: '',
                    })
                    this.filesUploadUpdate.push({
                        //name: uploadedFiles[i].name,
                        name: fileNameUpload +'.' + uploadedFiles[i].name.split('.')[1],
                        size: uploadedFiles[i].size,
                        type: uploadedFiles[i].type,
                        file: uploadedFiles[i],
                    })
                  }
                //debugger
            S3Client.uploadFile(uploadedFiles[i],fileNameUpload,uploadedFiles[i].name.split('.')[1]).then(data=>
                {
                    
                    this.IsUpdated = true;
                    this.cnt++;
                    var userD = [];
                    var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    ////debugger
                    if (storedNames == null) {
                        userD[0] = data.location
                    }
                    else    
                    {
                     for ( let i = 0; i < storedNames.length; i++ ) {
                            userD[i] = storedNames[i];
                        }   
                        userD[storedNames.length] = data.location;
                    }
                    localStorage.setItem("FileContent", JSON.stringify(userD));    
                    if(this.cnt == this.uploadcnt) {
                        this._loadingEnd()
                    }
                }).catch(err=>{
                    //debugger
                })
            }
            
              for ( let i = 0; i < uploadedFiles.length; i++ ) {
                debugger
                  if (this.formType == "INSERT") {
                    // this.filesUpload.push({
                    //     name: uploadedFiles[i].name,
                    //     size: uploadedFiles[i].size,
                    //     type: uploadedFiles[i].type,
                    //     file: uploadedFiles[i],
                    // })
                  }
                  else  {
                      
                    // this.IsUpdated = true;
                    // this.filesUpload.push({
                    //     name: uploadedFiles[i].name,
                    //     type: uploadedFiles[i].type,
                    //     size: uploadedFiles[i].size,
                    //     path: '',
                    // })
                    // this.filesUploadUpdate.push({
                    //     name: uploadedFiles[i].name,
                    //     size: uploadedFiles[i].size,
                    //     type: uploadedFiles[i].type,
                    //     file: uploadedFiles[i],
                    // })
                  }
            }
            if (this.formType == 'RES_UPDATE') {
                const isValid = await this.$refs['observer'].validate()
                if(this.item.Files.length > 0 || this.filesUpload.length > 0 && isValid )
                    this.IsVal = true
                else 
                    this.IsVal = false
            }
            if(this.formType == 'RES_UPDATE'){
                //debugger
                const isValid = await this.$refs['observer'].validate()
                if(isValid){
                    
                    if(this.item.status == 'Bounce')
                    {
                        this.$refs.modal.$refs.footer.children[1].hidden = true
                        this.$refs.modal.$refs.footer.children[2].hidden = false
                    }
                    else
                    {
                        this.$refs.modal.$refs.body.children[0].hidden = true
                        this.$refs.modal.$refs.footer.children[1].hidden = true
                        this.$refs.modal.$refs.footer.children[2].hidden = false
                    }
                    
                }
                
            }
            this.$refs.files.value = ''
           console.clear();
        },
        handleChangeThumbnail(val) {
            this.thumbnailIndex = val
            this.filesUpload.forEach((x, index) => {
                if(index === val) {
                    x.is_thumbnail = true
                } else {
                    x.is_thumbnail = false
                }
            })
           console.clear();
        },
        forceRerenderInsert(list) {
            this.formType = 'INSERT'
            list.forEach((x, i) => 
            {
                this.RecallsList.push(x.userRecallId.trim())
            });
            this.$refs.modal.show()
           console.clear();
            this.class1 = ''
            this.class2 = ''
            this.class3 = ''
            this.class4 = ''
            this.css1 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css2 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css3 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css4 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            //debugger
            this.notificationValue = this.item.notificationValue != undefined ? this.item.notificationValue : this.notificationValue
            if(this.item.notificationValue == 1)
            {
                this.class1 = 'primary'
                this.css1 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = true
            }
            else if(this.item.notificationValue == 2)
            {
                this.class2 = 'primary'
                this.css2 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                //this.item.IsFirstNotice = false
            }
            else if(this.item.notificationValue == 3)
            {
                this.class3 = 'primary'
                this.css3 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                //this.item.IsFirstNotice = false
            }
            else if(this.item.notificationValue == 4)
            {
                this.class4 = 'primary'
                this.css4 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                //this.item.IsFirstNotice = false
            }
        },
        forceRerenderUpdate(val,list,Mode) {
            this.formType = Mode
            ////debugger
            list.forEach((x, i) => 
            {
                this.RecallsList.push(x.userRecallId.trim())
            });
            this.updateRecallId = val.userRecallId.trim()
            //debugger
            var Ndate = "";
            if (val.notificationDate != "" && val.notificationDate != null && !val.notificationDate.includes('-')) {
                var Tdate = '';
                if (val.notificationDate.includes('T')){
                    Tdate = new Date(val.notificationDate.split('T')[0].split('-')[2].split(' ')[0] + "-" + val.notificationDate.split('T')[0].split('-')[1] + "-" + (parseInt(val.notificationDate.split('T')[0].split('-')[0])));
                }
                else if (val.notificationDate.includes('-'))
                {
                    Tdate = val.notificationDate
                }
                else   
                {
                    Tdate = new Date(val.notificationDate.split('/')[2].split(' ')[0] + "-" + val.notificationDate.split('/')[1] + "-" + (parseInt(val.notificationDate.split('/')[0])));
                }
                Tdate.setDate((parseInt(val.notificationDate.split('/')[0])))
                var mm = Tdate.getMonth() + 1;
                var dd = Tdate.getDate();
                Ndate = ((mm > 9 ? '' : '0') + mm) + "/" + ((dd > 9 ? '' : '0') + dd) + "/" + Tdate.getFullYear();
            }
            //debugger
            this.item = {
                suppliername: val.suppliername,
                name: val.name,
                recallid: val.userRecallId,
                id: val.recallid,
                status: val.status =="ERROR" ? 'Bounce' : val.status,
                response_email: val.supplierEmail,
                notificationDate: Ndate,
                disposalMethod: val.disposalMethod == "Destroyed" ? true : (val.disposalMethod == "Returned" ? false : ''),
                disposalMethodValue: val.disposalMethod,
                affectedProduct: val.affectedProduct == "true" ? true : false,
                documents: null,
                createdBy: null,
                supplierEmail: val.supplierEmail,
                Files: [],
                IsFirstNotice: val.IsFirstNotice,
                notificationValue: val.notificationValue
            }
            val.Files.forEach((x, i) => {
                if (x.FilePath != "Empty") {
                    var filename = x.FileName
                    //debugger
                    // if(x.FileType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                    // {
                    //     filename = filename.split('.')[0] + ".docx"
                    // }
                    // else if(x.FileType == "text/plain")
                    // {
                    //     filename = filename.split('.')[0] + ".txt"
                    // }
                    // else if(x.FileType == "application/vnd.ms-excel")
                    // {
                    //     filename = filename.split('.')[0] + ".csv"
                    // }
                    // else if(x.FileType == "application/vnd.openxmlformats-officedocument.presentationml.presentation" || x.FileType == 'application/vnd.ms-powerpoint')
                    // {
                    //     filename = filename.split('.')[0] + ".ppt"
                    // }
                    // else if(x.FileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                    // {
                    //     filename = filename.split('.')[0] + ".xlsx"
                    // }
                    //application/vnd.ms-powerpoint
                    this.filesUpload.push({
                        //name: x.FileName.substr(x.FileName.lastIndexOf('/') + 1),   
                        name: filename,   
                        extension: x.FileName.substr(x.FileName.lastIndexOf('/') + 1).split(".")[1],
                        type: x.FileType,
                        size: x.fileSize,
                        path: x.FilePath,
                    })
                    this.filesUploadOriginal.push({
                        name: x.FileName.substr(x.FileName.lastIndexOf('/') + 1),
                        extension: x.FileName.substr(x.FileName.lastIndexOf('/') + 1).split(".")[1],
                        type: x.FileType,
                        size: x.fileSize,
                        path: x.FilePath,
                    })        
                }
        })
        //debugger
       console.clear();
        this.$refs.modal.show()
        this.class1 = ''
            this.class2 = ''
            this.class3 = ''
            this.class4 = ''
            this.css1 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css2 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css3 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css4 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            //debugger
            this.notificationValue = this.item.notificationValue
            if(this.item.notificationValue == 1)
            {
                this.class1 = 'primary'
                this.css1 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = true
            }
            else if(this.item.notificationValue == 2)
            {
                this.class2 = 'primary'
                this.css2 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                //this.item.IsFirstNotice = false
            }
            else if(this.item.notificationValue == 3)
            {
                this.class3 = 'primary'
                this.css3 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                //this.item.IsFirstNotice = false
            }
            else if(this.item.notificationValue == 4)
            {
                this.class4 = 'primary'
                this.css4 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                //this.item.IsFirstNotice = false
            }
        },
        
        async createRecall(e) {
            ////debugger
           console.clear();
            const isValid = await this.$refs['observer'].validate()
            ////debugger
            if(isValid)
            {  
                if(!this.RecallsList.includes(this.item.recallid.trim()))
                {
                    if (!this.filesUpload.length == 0) {
                    var fileP =[];
                    var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    ////debugger
                    this.filesUpload.forEach((x, i) => {
                                let s3U = this.S3Url + "/"
                                let path = '';
                                storedNames.forEach((y, i) => {
                                    var fn = x.name.split(".")[0]
                                    if(y.toString().includes(fn))
                                    {
                                        path = y
                                    }
                                    })
                                var fName = path.split(s3U)[1];
                                var nFname = fName.split('/')[1] != undefined ? fName.split('/')[1] : fName.split('/')[0];
                                ////debugger
                                fileP.push({
                                    "filePath": path,
                                    "fileName": nFname,
                                    "fileType": x.type,
                                    "fileSize": x.size
                                })
                            })
                            ////debugger
                                    var uId = this.GetUserId
                                    var Ndate = "";
                if (this.item.notificationDate != "" && this.item.notificationDate != null) {
                    var Tdate = new Date(this.item.notificationDate);
                    var mm = Tdate.getMonth() + 1;
                    var dd = Tdate.getDate();
                    Ndate = ((dd > 9 ? '' : '0') + dd) + "/" + ((mm > 9 ? '' : '0') + mm) + "/" + Tdate.getFullYear() + " " + Tdate.getHours() + ":" + Tdate.getMinutes() + ":" + Tdate.getSeconds();
                }
                                    var itemData ={
                                        "Files": fileP,
                                        "RecallId": null,
                                        "supplierEmail": this.item.response_email == null ? "" : this.item.response_email ,
                                        "supplierName": this.item.suppliername,
                                        "supplierRecallId": this.item.recallid,
                                        "supplierRecallName": this.item.name,
                                        "userId": uId,
                                        'userRecallId': this.item.recallid,
                                        "responceDate": "10/10/2020",
                                        "responceStatus": "DRAFT",
                                        "createdAt": "10/10/2020",
                                        "messageeId": "",
                                        "disposalMethod": this.item.disposalMethodValue, 
                                        "affectedProduct": this.item.affectedProduct, 
                                        "notificationDate": Ndate, 
                                        "notificationValue": this.notificationValue,
                                        "isFirstNotice": this.notificationValue == 1 ? true :  this.item.IsFirstNotice
                                        }
                                    userServices.createRecall(itemData).then(resp=>{          
                                        if(resp.data.data){   
                                            this.$refs.modal.hide()
                                            this.$parent.$options.parent.fetchData("Recall created successfully",this.item.recallid,'')
                                        }
                                    })
                                }
                    if (this.filesUpload.length == 0) {
                        var uId = this.GetUserId;
                        var fileP = [{
                            "filePath": "Empty",
                            "fileName": "",
                            "fileType": "",
                            "fileSize": ""
                        }]
                        var Ndate = "";
                if (this.item.notificationDate != "" && this.item.notificationDate != null) {
                    var Tdate = new Date(this.item.notificationDate);
                    var mm = Tdate.getMonth() + 1;
                    var dd = Tdate.getDate();
                    Ndate = ((dd > 9 ? '' : '0') + dd) + "/" + ((mm > 9 ? '' : '0') + mm) + "/" + Tdate.getFullYear() + " " + Tdate.getHours() + ":" + Tdate.getMinutes() + ":" + Tdate.getSeconds();
                }
                        var itemData ={
                            "Files": fileP,
                            "RecallId": null,
                            "supplierEmail": this.item.response_email == null ? "" : this.item.response_email,
                            "supplierName": this.item.suppliername,
                            "supplierRecallId": this.item.recallid,
                            "supplierRecallName": this.item.name,
                            "userId": uId,
                            'userRecallId': this.item.recallid,
                            "responceDate": "10/10/2020",
                            "responceStatus": "DRAFT",
                            "createdAt": "10/10/2020",
                            "messageeId": "",
                            "disposalMethod": this.item.disposalMethodValue, 
                            "affectedProduct": this.item.affectedProduct, 
                            "notificationDate": Ndate,
                            "notificationValue": this.notificationValue,
                            "isFirstNotice": this.notificationValue == 1 ? true :  this.item.IsFirstNotice
                            }
                        userServices.createRecall(itemData).then(resp=>{          
                            //debugger
                            if(resp.data.data){   
                                ////debugger
                                this.$refs.modal.hide()
                                this.$parent.$options.parent.fetchData("Recall created successfully",this.item.recallid,'')
                            }
                        })   
                    }
                }
                else
                {
                    this._showToast("Recall Id is already existed.", { variant: 'danger',title:'Error' })
                }
            }
           console.clear();
        },
        async updateRecall(e) {
           console.clear();
            //debugger
            var isValid = await this.$refs['observer'].validate()
            if(isValid)
            {   
                if(!this.RecallsList.includes(this.item.recallid.trim()) || this.updateRecallId.trim() == this.item.recallid.trim())
                {
            if (this.IsUpdated) {
                var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                var fileP =[];
                this.filesUploadUpdate.forEach((x, i) => {
                    let s3U = this.S3Url + "/"
                    let path = '';
                    storedNames.forEach((y, i) => {
                        var fn = x.name.split(".")[0]
                        if(y.toString().includes(fn))
                        {
                            path = y
                        }
                        })
                    var fName = path.split(s3U)[1];
                    fileP.push({
                        "filePath": path,
                        "fileName": fName.split('/')[1],
                        "fileType": x.type,
                        "fileSize": x.size
                    })
                })
                this.filesUploadOriginal.forEach((x, i) => {
                    fileP.push({
                            "FilePath": x.path,
                            "FileName": x.name,
                            "FileType": x.type,
                            "fileSize": x.size
                        })
                    
                });
                var uId = this.GetUserId;
                var Ndate = "";
                if (this.item.notificationDate != "" && this.item.notificationDate != null) {
                    var Tdate = new Date(this.item.notificationDate);
                    var mm = Tdate.getMonth() + 1;
                    var dd = Tdate.getDate();
                    Ndate = ((dd > 9 ? '' : '0') + dd) + "/" + ((mm > 9 ? '' : '0') + mm) + "/" + Tdate.getFullYear() + " " + Tdate.getHours() + ":" + Tdate.getMinutes() + ":" + Tdate.getSeconds();
                }

                var itemData ={
                    "Files": fileP,
                    "RecallId": this.item.id,
                    "supplierEmail": this.item.response_email == null ? "" : this.item.response_email,
                    "supplierName": this.item.suppliername,
                    "supplierRecallId": this.item.recallid,
                    "supplierRecallName": this.item.name,
                    "userId": uId,
                    'userRecallId': this.item.recallid,
                    "responceStatus": "DRAFT",//this.item.status,
                    "createdAt": "10/10/2020",
                    "messageeId": "",
                    "disposalMethod": this.item.disposalMethodValue, 
                    "affectedProduct": this.item.affectedProduct, 
                    "notificationDate": Ndate,
                    "notificationValue": this.notificationValue,
                    "isFirstNotice": this.notificationValue == 1 ? true :  this.item.IsFirstNotice
                    }
             //debugger
                userServices.updateRecall(itemData).then(resp=>{          
                    if(resp.data.data){   
                        this.$refs.modal.hide()
                        this._loadingEnd()
                        this.$parent.$options.parent.fetchData("Recall Updated successfully")
                        var itemData ={
                            "Files": fileP,
                            "RecallId": this.item.id,
                            "supplierEmail": this.item.response_email == null ? "" : this.item.response_email ,
                            "supplierName": this.item.suppliername,
                            "supplierRecallId": this.item.recallid,
                            "supplierRecallName": this.item.name,
                            "userId": uId,
                            'userRecallId': this.item.recallid,
                            "responceDate": "10/10/2020",
                            "responceStatus": "DRAFT",
                            "createdAt": "10/10/2020",
                            "messageeId": "",
                            "FileLen": fileP.length,
                            "disposalMethod": this.item.disposalMethodValue, 
                            "affectedProduct": this.item.affectedProduct, 
                            "notificationDate": Ndate, 
                            "notificationValue": this.notificationValue,
                            "isFirstNotice": this.notificationValue == 1 ? true :  this.item.IsFirstNotice
                            }
                            //debugger
                        this.$parent.$options.parent.RespClick(itemData)
                    }
                })
                        }
            if (!this.IsUpdated) {
                var uId = this.GetUserId;
                var fileP =[];
                var filePD =[];
                if (this.filesUploadOriginal.length >0) {
                    this.filesUploadOriginal.forEach((x, i) => {
                        fileP.push({
                                "FilePath": x.path,
                                "FileName": x.name,
                                "FileType": x.type,
                                "fileSize": x.size
                            })
                            filePD.push({
                                "FilePath": x.path,
                                "FileName": x.name,
                                "FileType": x.type,
                                "fileSize": x.size
                            })
                    });    
                }
                else {
                    fileP = [{
                        "filePath": "Empty",
                        "fileName": "",
                        "fileType": "",
                        "fileSize": ""
                    }]
                }
                var Ndate = "";
                if (this.item.notificationDate != "" && this.item.notificationDate != null) {
                    var Tdate = new Date(this.item.notificationDate);
                    var mm = Tdate.getMonth() + 1;
                    var dd = Tdate.getDate();
                    Ndate = ((dd > 9 ? '' : '0') + dd) + "/" + ((mm > 9 ? '' : '0') + mm) + "/" + Tdate.getFullYear() + " " + Tdate.getHours() + ":" + Tdate.getMinutes() + ":" + Tdate.getSeconds();
                }
                            var itemData ={
                                "Files": fileP,
                                "RecallId": this.item.id,
                                "supplierEmail": this.item.response_email == null ? "" : this.item.response_email,
                                "supplierName": this.item.suppliername,
                                "supplierRecallId": this.item.recallid,
                                "supplierRecallName": this.item.name,
                                "userId": uId,
                                'userRecallId': this.item.recallid,
                                "responceStatus": "DRAFT",//this.item.status,
                                "createdAt": "10/10/2020",
                                "disposalMethod": this.item.disposalMethodValue, 
                                "affectedProduct": this.item.affectedProduct, 
                                "notificationDate": Ndate,
                                "notificationValue": this.notificationValue,
                                "isFirstNotice": this.notificationValue == 1 ? true :  this.item.IsFirstNotice
                                }
                                //debugger
                                //
                            userServices.updateRecall(itemData).then(resp=>{          
                               
                                if(resp.data.config.data){   
                                    this.$refs.modal.hide()
                                    this.$parent.$options.parent.fetchData("Recall Updated successfully")
                                    var itemData ={
                                        "Files": filePD,
                                        "RecallId": this.item.id,
                                        "supplierEmail": this.item.response_email == null ? "" : this.item.response_email ,
                                        "supplierName": this.item.suppliername,
                                        "supplierRecallId": this.item.recallid,
                                        "supplierRecallName": this.item.name,
                                        "userId": uId,
                                        'userRecallId': this.item.recallid,
                                        "responceDate": "10/10/2020",
                                        "responceStatus": "DRAFT",
                                        "createdAt": "10/10/2020",
                                        "messageeId": "",
                                        "FileLen": filePD.length,
                                        "disposalMethod": this.item.disposalMethodValue, 
                                        "affectedProduct": this.item.affectedProduct, 
                                        "notificationDate": Ndate, 
                                        "notificationValue": this.notificationValue,
                                        "isFirstNotice": this.notificationValue == 1 ? true :  this.item.IsFirstNotice
                                        }
                                        //debugger
                                    this.$parent.$options.parent.RespClick(itemData)
                                }
                            })
            }
        }
        else
        {
            this._showToast("Recall Id is already existed.", { variant: 'danger',title:'Error' })
        }
        }
       console.clear();
        },
        notificationclick(eve) {
            this.notificationValue = eve
            this.class1 = ''
            this.class2 = ''
            this.class3 = ''
            this.class4 = ''
            this.$refs.bnt1.style = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.$refs.bnt2.style = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.$refs.bnt3.style = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.$refs.bnt4.style = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            //debugger
            if(eve == 1)
            {
                this.class1 = 'primary'
                this.$refs.bnt1.style = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = true
            }
            else if(eve == 2)
            {
                this.class2 = 'primary'
                this.$refs.bnt2.style = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = false
            }
            else if(eve == 3)
            {
                this.class3 = 'primary'
                this.$refs.bnt3.style = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = false
            }
            else if(eve == 4)
            {
                this.class4 = 'primary'
                this.$refs.bnt4.style = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = false
            }
        },
        isFirstNoticeClicked(eve) {
            if (eve.target.checked) {
                this.item.isFirstNotice = true 
            }
            else {
                this.item.IsFirstNotice = false 
            }
        },
        async onChangeDis(eve) {
            //debugger
            const isValid = await this.$refs['observer'].validate()
            if(eve.Files.length > 0 || this.filesUpload.length > 0 && isValid )
            this.IsVal = true
            else 
            this.IsVal = false   
        }
    }
}